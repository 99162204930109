body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 3em;
}

.svg {
  -webkit-filter: invert(100%);
}

a {
  color: #303f9f;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  color: #303f9f;
  text-decoration: underline;
}

.nav-link {
  padding-right: .1em;
}

.container {
  max-width: 35rem;
  margin: 0 auto;
  padding: 0 1em;
  margin: auto;
}

nav {
  display: flex;
  float: right;
}

.danger {
  color: red;
}
